import { gql } from "@apollo/client";

export const NEW_STD_ORDER = (
    customer_email,
    increment_id,
    items,
    shipping_details,
    branch,
    shipping_method,
    fulfiller,
    order_total
) => {
    return gql`
        mutation {
            newStdOrder(
                input: {
                    customer_email: "${customer_email}"
                    increment_id: "${increment_id}"
                    items: ${items}
                    shipping_details: {
                        street: "${shipping_details.street}"
                        city: "${shipping_details.city}"
                        region: "${shipping_details.region}"
                        postcode: "${shipping_details.postcode}"
                    }
                    branch: "${branch}"
                    shipping_method: "${shipping_method.toLowerCase()}"
                    fulfiller: "${fulfiller}"
                    order_total: ${order_total}
                }
            ) {
                customer_email
                items {
                    configurable
                    simple
                    qty
                }
                shipping_details {
                    street
                    city
                    region
                    postcode
                }
                branch
                shipping_method
                fulfiller
                order_total
            }
        }
    `;
}