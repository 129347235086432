import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import config from "../../../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SizeSelector from "../products/size-selector";
import PropTypes from "prop-types";
import PlaceholderImage from "../../../containers/stores/catalog/placeholder.jpeg";
import Placeholder from "react-bootstrap/Placeholder";
import LoaderButton from "../../LoaderButton";
import {DISABLED, IN_STOCK, OUT_OF_STOCK} from "../../../constants/actions/CatalogAvailabilityTypes";

/**
 * Product card for a catalog view
 * takes in a product and two functions as a callback for user to handle size selection/product selection
 */
export default class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render(){
        let product = this.props.product
        if(product.price || product.status === DISABLED){
            return this.renderLoaded(product)
        }else {
            return this.renderPlaceholder(product)
        }
    }
    renderLoaded(product){
        let base_image =null
        if('base_image' in product){
            base_image = product.base_image;
        }
        let status = product.status ? product.status: null
        return(
            <Card border="light" className={"border-0"}>
                {(status === OUT_OF_STOCK || status === DISABLED) ? <div className="out-of-stock-overlay"></div> :null}
                <Card.Img
                    width = {184}
                    height = {276}
                    alt={"can not load image"}
                    src={base_image ? `${config.magento.baseUrl}media/catalog/product${base_image}` : PlaceholderImage}
                />
                <Card.Body>
                    <Card.Title>
                        <h3 style={{ textTransform: 'uppercase'}} >{product.supplier}</h3>
                    </Card.Title>
                    <Row>
                        <Col sm ={10}> <span> {product.simple_name ?? product.product_name} </span></Col>
                        <Col sm ={10}> <span> {product.color ?? ""} </span></Col>
                    </Row>
                    <Row>
                        <Col sm ={5}> <span style={{fontWeight: 700}}> ${parseInt(product.price).toFixed(2)}</span></Col>
                    </Row>
                    {status === IN_STOCK ?
                        <Row style={{marginTop:'1rem'}}>
                            <Col sm={6}>
                                <SizeSelector sizes={product.sizes} updateSize={(size) => this.props.selectSize(size)}/>
                            </Col>

                            <Col sm = {6}>
                                <LoaderButton
                                    className="btn-block small"
                                    variant={"dark"}
                                    onClick={()=>this.props.selectProduct(product)}
                                    disabled = {!product.selected_size || this.props.isLoading}
                                    text={"ADD"}
                                >
                                </LoaderButton>
                            </Col>
                        </Row>
                    :
                        <Row style={{marginTop:'1rem'}}>
                            <Col sm ={10}> <span style={{fontWeight: 700}}> OUT OF STOCK</span></Col>
                        </Row>
                    }

                </Card.Body>
            </Card>
        )
    }
    renderPlaceholder(product){
        let base_image =null
        if('base_image' in product){
            base_image = product.base_image;
        }
        return(
            <Card border="light" className={"border-0"}>
                <Card.Img
                    width = {184}
                    height = {276}
                    src={base_image ? `${config.magento.baseUrl}media/catalog/product${base_image}` : PlaceholderImage}
                    className = "loading-img"
                />
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} />
                        <Placeholder xs={4} />
                        <Placeholder xs={2} />
                    </Placeholder>
                    <Placeholder.Button variant="dark" xs={6} />
                </Card.Body>
            </Card>
        )
    }
}

ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
    selectProduct: PropTypes.func.isRequired,
    selectSize: PropTypes.func.isRequired
};
