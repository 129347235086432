import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import config from "../../../config";
import Row from "react-bootstrap/Row";
import SizeSelector from "../products/size-selector";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import LoaderButton from "../../LoaderButton";
import PlaceholderImage from "../../../containers/stores/catalog/placeholder.jpeg";
import { Placeholder } from "react-bootstrap";

/**
 * Component is used to display cart items in a horizontal fashion.
 * takes in a product/item and returns callbacks for handling size
 * or removing item from cart.
 */
export default class ProductCard extends Component {

    render(){
        let disabled = this.props.disabled ?? false
        let item = this.props.cart_item
        let price = item.price
        let default_size = item.selected_size ?? null
        let base_image =null
        if('base_image' in item){
            base_image = item.base_image;
        }
        return(
            <Row>
                <Col sm={3}>
                    <Card.Img
                        width = {91}
                        height = {137}
                        alt={""}
                        src={base_image ? `${config.magento.baseUrl}media/catalog/product${base_image}` : PlaceholderImage}
                    />
                </Col>
                <Col sm={9}>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <Card.Title>
                                    <h5 style={{ textTransform: 'uppercase'}} >{item.supplier}</h5>
                                </Card.Title>
                            </Col>
                            <Col sm={{ span: 1, offset: 4 }}>
                                {item.uid
                                    ? <span style={{fontWeight: 700}}>
                                        <span style={{textDecoration: item.discount_total ? "line-through" : "none", color: item.discount_total ? "#af1818" : null}}>
                                            ${price.toFixed(2)}
                                        </span>
                                        <br />
                                        {
                                            item.discount_total !== 0
                                            ? <span>${(price - item.discount_total).toFixed(2)}</span>
                                            : null
                                        }
                                      </span>
                                    : <Placeholder as={Card.Title} animation="glow"> <Placeholder xs={12} /> </Placeholder>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm ={10}> <span> {item.product_name} </span></Col>
                        </Row>
                        {default_size ?
                            <Row>
                                <Col sm ={10}> <span> QTY: {item.qty} </span></Col><Col sm ={8}> <span> R-CODE: {default_size.simple} </span></Col>
                            </Row>
                            :null
                        }
                        {!disabled ?
                            <Row>
                                <Col lg={4} xl={3}>
                                    {item.uid
                                        ? <SizeSelector
                                            defaultSelection={default_size}
                                            sizes={item.sizes}
                                            updateSize={(size) => this.props.updateSize(size)}
                                        />
                                        : <Placeholder as={Card.Title} animation="glow"> <Placeholder xs={12} /> </Placeholder>
                                    }
                                </Col>
                                <Col lg={{ span: 3, offset: 4 }} xl={{ span: 3, offset: 6 }}  >
                                    {item.uid
                                        ? <LoaderButton
                                            className="btn small"
                                            variant={"outline-danger"}
                                            onClick={()=>this.props.removeFromCart(item)}
                                            text={"REMOVE"}
                                        />
                                        : <Placeholder as={Card.Title} animation="glow"> <Placeholder xs={9} /> </Placeholder>
                                    }
                                </Col>
                            </Row>
                        : null}
                    </Card.Body>
                </Col>
            </Row>
        )
    }
}

ProductCard.propTypes = {
    disabled: PropTypes.bool,
    cart_item: PropTypes.object.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    updateSize: PropTypes.func.isRequired
};
