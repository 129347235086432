import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import LoaderButton from "../../LoaderButton";

const states_list = [
    {value:null,label:"Select State"},
    {value:"QLD",label:"Queensland"},
    {value:"ACT",label:"Australian Capital Territory"},
    {value:"VIC",label:"New South Wales"},
    {value:"NSW",label:"Northern Territory"},
    {value:"SA",label:"South Australia"},
    {value:"WA",label:"Western Australia"},
    {value:"TAS",label:"Tasmania"}]
/**
 * Component for a address form submission which returns a object
 * with customers address information
 * address/delivery details
 */
export default class CheckoutAddressForm extends Component {
    constructor(props) {
        super(props);
        this.addressRef = React.createRef();
        this.state = {
            address_details: null,
            selected_state: null
        }
    }

    render(){
        return(
            <Row>
                <Formik
                    validateOnChange={false}ß
                    initialValues={{...this.props.address_details}}
                    validationSchema={buildFormSchema()}
                    onSubmit={(values,{setStatus}) => {
                        try{
                            this.props.submitDetails(values);
                        }catch (e) {
                            console.log(e.message);
                            setStatus(e.message)
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          status
                          /* and other goodies */
                      }) => (
                        <Form noValidate onSubmit= {handleSubmit} className = "customer-manual-address-form">
                            {status?
                                <Row>
                                    <Col sm={11}>
                                        <Alert variant="danger" onClose={() => setShow(false)}>
                                            {status}!
                                        </Alert>
                                    </Col>
                                </Row>
                                : null}
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="street">
                                        <Form.Control
                                            name={"street"}
                                            onChange={handleChange}
                                            value = {values.street}
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Enter Street"
                                            isValid={touched.street && !getIn(errors,"street")}
                                            isInvalid = {!!getIn(errors,"street")}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.street}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={7}>
                                    <Form.Group className="mb-3" controlId="city">
                                        <Form.Control
                                            name={"city"}
                                            onChange={handleChange}
                                            value={values.city}
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Suburb"
                                            isInvalid = {!!getIn(errors,"city")}/>
                                    </Form.Group>
                                </Col>
                                <Col sm={5}>
                                    <Form.Group className="mb-3" controlId="postcode">
                                        <Form.Control
                                            name={"postcode"}
                                            onChange={handleChange}
                                            value={values.postcode}
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Postcode"
                                            isInvalid = {!!getIn(errors,"postcode")}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group className="mb-3" controlId="state">
                                        <Form.Select
                                            name={"region_code"}
                                            onChange={handleChange}
                                            value = {values.region_code}
                                            placeholder="Please Select a region,state or province"
                                            isInvalid = {!!getIn(errors,"region_code")}
                                        >
                                            {states_list.map((state,i) =>
                                                <option
                                                    key={i}
                                                    value={state.value}
                                                >
                                                    {state.label}
                                                </option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{marginTop:'1rem'}}>
                                <Col sm={{span:2, offset:10}}>
                                    <LoaderButton
                                        className={"btn-block"}
                                        variant="success"
                                        type="submit"
                                        disabled = {this.props.disabled}
                                        text={"SAVE"}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Row>
        )
    }
}

function capitalizeName(name) {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
}

function buildFormSchema() {

    return  yup.object().shape({
        street: yup.string().required("Street is required"),
        city: yup.string().required('City required'),
        postcode: yup.string().required("postcode is required"),
        region_code: yup.string().required("State is required")
    })
}

CheckoutAddressForm.propTypes = {
    submitDetails: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    address_details: PropTypes.object,
};

CheckoutAddressForm.defaultProps = {
    disabled:false,
    address_details : {
        street: '',
        city: '',
        postcode: '',
        region_code: '',
        country_id:"AU"
    }
};
