import {searchProducts,clearProducts} from "../../actions/catalog/CatalogActions"
import {getFulfillerData} from "../../actions/checkout/CheckoutActions"
import "./store-orders.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card";
import ProductSearch from "../../components/search/product/ProductSearch"
import CatalogGridView from "./catalog/GridView"
import CheckoutView from "./checkout/CheckoutView";
import TillLogin from "../../components/login/staff-number-login"
import React, { Component }  from 'react';
import $ from 'jquery';
import config from "../../config"
import { MageQL } from '../../graphql/MagentoClient';
import { ApolloProvider } from '@apollo/client';



$(function() {
    // Use the Production environment
    //Harmony.useEnv(Harmony.ENV_PREVIEW);
    Harmony.init(config.mastersoft.user, config.mastersoft.key, Harmony.AUSTRALIA);
    // Use the JSONP protocol
    Harmony.useProtocol(Harmony.JSONP);
});


let pilotClosed = {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5rem 2rem 5rem 2rem',

}
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const mapStateToProps = state => {
    return {
        stage: state.checkoutState.stage,
        fulfiller:state.checkoutState.fulfiller
       };
};

const mapDispatchToProps = dispatch =>{
    return {
        clearProducts: () => {
            dispatch(clearProducts())
        },
        searchProducts: (query_string) =>{
            dispatch(searchProducts(query_string))
        },
        getFulfillerData: (till_number) => {
            dispatch(getFulfillerData(till_number))
        }
    }
};
class StoreOrderView extends Component {

    /**
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.stage !== this.props.stage){
        if(this.props.stage === 4){
            this.props.clearProducts();
        }
    }
    }

    handleProductSearch = (search_string) =>{
        this.props.searchProducts(search_string)
    }

    handleLogin = (till_number) => {
        this.props.getFulfillerData(till_number);
    }

    render() {
        let stage = this.props.stage
        let loggedIn = this.props.fulfiller ? this.props.fulfiller.length > 1 : false
        if(loggedIn)
            return this.renderStoreView(stage)
        else
            // return (
            //   <div style ={pilotClosed}>
            //       <h1>
            //           STORE TO DOOR PILOT CLOSED
            //       </h1>
            //       <h3>
            //           Thank you to our pilot stores for testing our Store To Door ordering system. We have taken your valuable feedback onboard and will optimise the Store To Door experience for stores and customers, in preparation for a national launch in 2022.
            //       </h3>
            //       <p>
            //           Have feedback about the Store To Door pilot? Email pmo@universalstore.com.au
            //       </p>
            //   </div>
            // )

            return this.renderLogInView()
    }

    renderLogInView() {
        return(
                <div className = "store-login-view">
                    <Row style={{marginBottom:'10px'}}>
                        <Col sm={12}>
                                <span style={{fontSize:'18px',fontWeight:'700',textTransform: 'uppercase'}}>
                                    Login in to place an order
                                </span>
                        </Col>
                    </Row>
                    <Row>
                        <TillLogin handleLogin={this.handleLogin}/>
                    </Row>
                </div>
            )
    }

    renderStoreView(stage)  {
        return(
            <div className = "store-order-view">
                <Row style={{paddingBottom:'1rem'}}>
                <Col sm={7}>
                    <h2 style={{ fontWeight:700,textTransform: 'uppercase'}} >Store To Door</h2>
                </Col>
                </Row>
                <Row>
                    {stage !== 4 ? this.renderOrderCatalog() : this.renderSuccessText() }
                    <Col sm = {stage !== 4 ? 5 : 6}>
                        <CheckoutView />
                    </Col>
                </Row>
            </div>
        )
}

    renderOrderCatalog(){
        return(
            <Col sm = { 7} className={"order-catalog border-end"}>
                <Row>
                    <Col sm ={8}>
                        <ProductSearch handleSearch = {(search)=>this.handleProductSearch(search)} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <CatalogGridView />
                    </Col>
                </Row>
            </Col>
        )
    }

    renderSuccessText(){
        return (
            <Col sm = {6} className={"order-success border-end"}>
                <Row className = "order-success-view">
                    <Col sm ={12}>
                        <Card border="dark">
                            <Card.Body>
                                <Card.Title><b>Whats Next?</b></Card.Title>
                                <Card.Text>
                                    <span style={{fontSize:'1rem'}}>Once payment is complete the customer will be
                                        updated of their order via email within the hour. </span>
                                </Card.Text>
                                <Card.Title><b>Problems while placing order?</b></Card.Title>
                                <span style={{fontSize:'1rem'}}>If you are unable to print the barcode, please write it
                                    down for this sale and contact Dev Support for help.</span>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Col>
        )
    }





}
const storeOrderView = connect(mapStateToProps, mapDispatchToProps)(StoreOrderView);
export default withRouter(storeOrderView);