import React, { Component } from "react";
import {connect} from "react-redux";
import LoaderButton from "../components/LoaderButton";
import Col from "react-bootstrap/Row";
import Input from "../components/form/inputs/Input";

const TILL_ONE="thermal_printer_one";
const TILL_TWO = "thermal_printer_two";


class TestPrinter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printer: null,
            connected:false,
            printer_address: '192.168.97.131',
            errors: [],
            success: []
        };
    }

    printReceipt() {
        let ip = this.state.printer_address;
        let builder = new window.epson.ePOSBuilder();
        builder.addTextStyle(false,false,true,builder.COLOR_3);
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addTextDouble(true,true)
        builder.addTextLineSpace(150);
        builder.addText('UNIVERSAL STORE\n');
        builder.addTextDouble(false,false)
        builder.addTextStyle(false,false,false,builder.COLOR_NONE);
        builder.addTextFont(builder.FONT_A);
        builder.addTextLineSpace(10);
        builder.addText('ID: 241232424234\n');
        builder.addText('Items being sent via post:\n');
        builder.addTextLineSpace(10);
        builder.addText('Tommy jeans - 54252342\n');
        builder.addTextLineSpace(200);
        builder.addText('Tommy tee - 6546352\n');
        builder.addTextLineSpace(50);
        builder.addBarcode('Kc4m3eIGrk7JRl6FG00UUorh7EWVHrdD', builder.BARCODE_GS1_128, builder.HRI_BELOW, builder.FONT_A, 4, 130);
        builder.addText('Thanks for shopping with Universal Store!\n');
        builder.addText('-----------------------------------------------\n');
        builder.addCut(builder.CUT_FEED);
        let address = `https://${ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
        let ePosDev = new window.epson.ePOSPrint(address);
        ePosDev.onreceive = function (res) {
            //When the printing is not successful, display a message
            if (!res.success) {alert('A print error occurred');}
        }
        ePosDev.send(builder.toString());
    }


    render(){
        return(
            <div style={{paddingTop:'20%'}} className={"store-login-wrapper"}>
                    <Col sm={12}>
                        <Input value={this.state.printer_address}
                               controlId={"printer-select"}
                               label={"Enter printer IP address"}
                               onChange={(e)=>this.setState({printer_address:e.target.value})}
                               placeholder={'192.168.203.2'}
                               name={"printer"}
                        />
                    </Col>
                    <Col sm={12}>
                        <LoaderButton
                            variant="secondary"
                            disabled = {!this.state.printer_address}
                            onClick={() =>this.printReceipt(this.state.printer_address)}
                            text = "PRINT"
                        />
                    </Col>
                {this.state.errors.map(error => <span>{error}</span>)}
            </div>
        )
    }
}

export default TestPrinter;