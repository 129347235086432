import { API } from "aws-amplify/lib";

export const getSearchedProducts = async (query, store_view_code) => {
    return API.post("catalog",'elastic/search',{
        body:{
            query,
            store_view_code
        }
    })
};

export const getProductInfo = async (products, store_view_code) => {
    return API.post("catalog",'get/products/extra',{
        body:{
            products,
            store_view_code
        }
    })
};