import React, { PureComponent } from "react";
import { Row, Col} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "./Add.css";
import {API, Auth} from "aws-amplify/lib/index";
import * as i from "icepick";
import * as _ from "lodash";
import moment from "moment/moment";
import * as EmailValidator from 'email-validator';

import Select from '../../components/form/inputs/Select'
import Input from '../../components/form/inputs/Input'
import DateInput from '../../components/form/inputs/DateInput'
import TimeInput from '../../components/form/inputs/TimeInput'
import Checkbox from '../../components/form/inputs/Checkbox'
import {loadSingleStoreRoles} from "../../actions/RolesActions";

import {connect} from "react-redux";
import {createUserTwo} from "../../actions/UsersActions";
import {errorNotification} from "../../actions/NotificationActions";
import {getStoresByUser} from "../../actions/StoreActions";
import {getIn} from "formik";

const mapStateToProps = state => {
    return { userRole: state.authState.role, username:state.authState.username}
};

const mapDispatchToProps = dispatch => {
    return {
        createUser : (cognitoParams,role,stores) => dispatch(createUserTwo(cognitoParams,role,stores)),
        errorNotification:(error)=> dispatch(errorNotification(error))
    }
};

class Add extends PureComponent {
    constructor(props) {
        super(props);
        this.getValidationState = this.getValidationState.bind(this);
        this.child = React.createRef();
        this.state = {
            isLoading: false,
            user:  {
                userId:"",

                email:{
                    data:"",
                    p_type: "2"
                },
                fName: {
                    data:"",
                    p_type: "2"
                },
                lName: {
                    data:"",
                    p_type: "2"
                },
                number: {
                    data:"",
                    p_type: "2"
                },

                address:{
                    data: {
                        street_one:"",
                        street_two:"",
                        suburb:"",
                        state:""
                    },
                    p_type: "1"
                },

                gender: {
                    data:"",
                    p_type: "2"
                },

                dob: {
                    data:"",
                    p_type: "1"
                },

                citizenship:{
                    data:"",
                    p_type: "1"
                },

                workrights:{
                    data:"",
                    p_type: "1"
                },

                availability:{
                    data:{
                        sunday: [{start:"12:00am",end:"12:00am"}],
                        monday: [{start:"12:00am",end:"12:00am"}],
                        tuesday: [{start:"12:00am",end:"12:00am"}],
                        wednesday: [{start:"12:00am",end:"12:00am"}],
                        thursday: [{start:"12:00am",end:"12:00am"}],
                        friday: [{start:"12:00am",end:"12:00am"}],
                        saturday: [{start:"12:00am",end:"12:00am"}]
                    },
                    p_type: "1"
                },
                availability_notes:{
                    data:"",
                    p_type: "1"
                },
                emergency:{
                    data:{
                        contactOne:{
                            fName: '',
                            lName: '',
                            relationship: '',
                            number: ''
                        },
                        contactTwo: {
                            fName: '',
                            lName: '',
                            relationship: '',
                            number: ''
                        }
                    },
                    p_type: "2"
                },
                user_references:{
                    data:{
                        contactOne:{
                            fName: '',
                            lName: '',
                            relationship: '',
                            organisation: '',
                            number: ''
                        },
                        contactTwo: {
                            fName: '',
                            lName: '',
                            relationship: '',
                            organisation: '',
                            number: ''
                        }
                    },
                    p_type: "2"
                }
            },
            bank: {
                userId: "",
                tfn: {
                    data:'',
                    p_type:"0"
                },
                tax_res:{
                    data:'',
                    p_type:"0"
                },
                tax_free:{
                    data:'',
                    p_type:"0"
                },
                tax_name:{
                    data:'',
                    p_type:"0"
                },
                help_loan:{
                    data:'',
                    p_type:"0"
                },
                fin_debt:{
                    data:'',
                    p_type:"0"
                },
                bank_name:{
                    data:'',
                    p_type:"0"
                },
                account_name:{
                    data:'',
                    p_type:"0"
                },
                bsb:{
                    data:'',
                    p_type:"0"
                },
                account_number:{
                    data:'',
                    p_type:"0"
                },
                tfn_dec:{
                    data:'',
                    p_type: "0"
                }
            },
            superann:{
                userId:"",
                existing_fund:{
                    data:false,
                    p_type:"0"
                },
                sup_fund:{
                    data:'',
                    p_type:"0"
                },
                account_name:{
                    data:'',
                    p_type:"0"
                },
                account_number:{
                    data:'',
                    p_type:"0"
                }
            },
            userRole:{
                userId: "na",
                futuraId: "na",

                role:"na",

                applicant_status:"NOT_LOGGED_IN",

                home_store:"na",

                stores: [{
                    role: "na",
                    store: "na"
                }],
                trial_type: "na",
                trial_date: "na",

                trial_time: "na",

                start_date:"na",

                end_date:"na",

                second_start_date:"na",

                termination_reason: "na",

                references: "na",

                ten_hour_rule:false,

                christmas_casual: false,

            },
            wageInfo:{
                userId:"na",
                employment_classification:"C REL1",
                wage:"na",
                pay_type:"na"
            },
            personalatts: {
                userId: "na",
                tfn: "na",
                pc: "na",
                offer:"na"

            },
            approvalatts:{
                userId:"na",
                hr_pc:"na",
                hr_tfn:"na",
                hr_vevo:"na",
                hr_removal:"na",
                man_pendingtrial:"na",
                hr_idcheck:"na",
                hr_resume:"na",
                pc:"na",
                tfn:"na"
            },
            stores:[],
            authKey:"",
            selectedStore:"",
            selectedType:"",
            selectedRole:"",
            storeTable: [],
            success:null,
            failure:null,

            roleTable: [

            ],
            typeTable : [
                {value:'INDUCTION', label: 'Induction'},
                {value:'TRIAL', label: 'Trial'}
            ],
            //All Validation Help-blocks for Add page
            storeHelp:false,
            currentUser:{},
            stores_dropdown:[],
            userLoaded:false

        };
    }

    //form validation, checks if email has been entered and passwords are correct


    async componentDidMount() {
       this.setState({stores_dropdown: await getStoresByUser(this.props.username)})
    }


    getUserRole = username =>{
        return API.get("users", `users/roles/get/${username}`);
    };

    validateForm() {
        const trialDate =this.state.userRole.trial_date;
        var date = moment(trialDate, 'DD/MM/YYYY',true);
        var today = moment(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1));

        var startDate = moment(new Date(today));
        var endDate   = moment(new Date(2026, 1, 15));

        const number = this.state.user.number.data;
        const pattern = /^(?:\+?(61))? ?(?:\((?= \)))?(0?[4-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

        if(this.state.selectedRole.value && this.state.selectedType.value&& this.state.selectedStore.value){
            if(date.isValid()){
                if(date.isBetween(startDate,endDate))
                {
                    return true
                }else if(!date.isBetween(startDate,endDate)) {
                    return false
                }
            } else if(trialDate.length>3 && trialDate.length<10){
                return false
            } else return false
        }

   }

    /**
     * creates a notification wherever you need, just give it the username of the current logged in user, type of message you want to send
     * and the message you want displayed
     * @param user
     * @param type
     * @param message
     */
   createNotification(user,type,message){
       let payload= {"type":type,"message":message};
       API.post("users","functions/mqtt/send",{
           body:{username:user,payload:payload}
       }).then(response=>{
           console.log(response)
       });
   }

    createHelp(type){
        if(type ==='store')
            this.setState({storeHelp:true});
    }

    /*TODO will change all input handling to something like this for tool tips and help buttons*/

    inputHandle = (type,path,value) =>{
        this.createHelp(type);
        this.handleUserRoleChange(type,path,value);
    };

    getValidationState(type){
        if(type==="store"){
            const store = this.state.selectedStore.value;
            if (store){
                return 'success';
            }
            else return null;
        }
        if(type ==="trial_date"){
            const trialDate =this.state.userRole.trial_date;
            var date = moment(trialDate, 'DD/MM/YYYY',true);
            var today = moment(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1));
            var startDate = moment(new Date(today));
            var endDate   = moment(new Date(2026, 1, 15));
            if(date.isValid()){
                if(date.isBetween(startDate,endDate))
                {
                    return 'success'
                }else if( !date.isBetween(startDate,endDate)) {
                    return 'error'
                }
            } else if(trialDate.length>3 && trialDate.length<10){
                    return 'error'
                } else return null
        }
        if(type==="email"){
            const email =this.state.user.email.data;
            if(EmailValidator.validate(email)){
                return 'success'
            }else if(email.length > 5){
                return 'error'
            }
            else return null
        }
        if(type ==="name"){
            const fName=this.state.user.fName.data;
            const lName = this.state.user.lName.data;
            if((fName.length> 0 && fName.length< 30) && (lName.length >0 && lName.length <40)){
                return 'success'
            }else if(fName.length> 30 || lName.length >30){
                return 'error'
            }else return null;
        }
        if(type=== "number"){
            const number = this.state.user.number.data;
            const patter = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
            const pattern = /^(?:\+?(61))? ?(?:\((?= \)))?(0?[4-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
            if((number.length > 6 && number.match(pattern)) && number.charAt(0)!=='4'){
                return 'success'
            }else if (number.length >= 9 && (number.charAt(0)!=='0' || number.charAt(0)!=='+' || number.charAt(0)!=='6'|| number.length >10)){
                return 'error'
            }else return null
        }
    }

    getFormValue = (path) => _.get(this.state.user, path);
    handleUserRoleChange = async(type, path, value) => {
        let newUserRole ="";
        // check to see which select box is editing it haha so dodgy
        if(type ==='role'){
            this.setState({selectedRole: value});
            const pathArr = _.toPath("stores[0].role");
            let oldUserRole = this.state.userRole;
            newUserRole = i.assocIn(oldUserRole, pathArr, value.value);
            const pathArrRole = _.toPath("role");
            newUserRole = i.assocIn(newUserRole, pathArrRole, value.value);
        }else if(type ==='store'){
            this.setState({
                selectedStore: value,
            });
            let testRoles =  await loadSingleStoreRoles(value,this.props.userRole);
            this.setState({roleTable: testRoles});

            let pathArr = _.toPath(path);
            let oldUserRole = this.state.userRole;
            newUserRole = i.assocIn(oldUserRole, pathArr, value.value);
            const pathArrStores = _.toPath("stores[0].store");
            newUserRole = i.assocIn(newUserRole, pathArrStores, value.value);

        }
        else if(type ==='type'){
            this.setState({selectedType: value});
            const pathArr = _.toPath(path);
            const oldUserRole = this.state.userRole;
            newUserRole = i.assocIn(oldUserRole, pathArr, value.value);
        } else {
            const pathArr = _.toPath(path);
            const oldUserRole = this.state.userRole;
            newUserRole= i.assocIn(oldUserRole, pathArr, value);
        }


        i.freeze(newUserRole);
        this.setState({
            userRole: newUserRole,
        });
    };
    handlePhoneNumberChange = (type, path, value) => {
        let newUser = "";
        if (type === "number") {
            const pathArr = _.toPath(path);
            const oldUser = this.state.user;
            newUser = i.assocIn(oldUser, pathArr, value);

            i.freeze(newUser);
            this.setState({
                user: newUser,
            });
        }
    };
    //validation for the code that is sent
    //handleChange method that is a general method for all inputs, each input changes the target dependent on the controlID
    handleChange = (path, value) => {
        //make email lowercase on input// reduces errors with email
        if(path === 'email.data'){
            const pathArr = _.toPath(path);
            const oldUser = this.state.user;
            const newUser = i.assocIn(oldUser, pathArr, value.toLowerCase());
            i.freeze(newUser);
            this.setState({
                user: newUser,
            });
        }else {
            const pathArr = _.toPath(path);
            const oldUser = this.state.user;
            const newUser = i.assocIn(oldUser, pathArr, value);
            i.freeze(newUser);
            this.setState({
                user: newUser,
            });
        }

    };


    // creates user using admincreateuser AWS
    adminCreateUser= async (params) =>{
        return await API.post("users","users/admin/create",{body:params})
    };

    getSingleStore=async(store) =>{
        return await API.get("stores",`get/${store}`)
    };

    handleSubmit = async event => {
        event.preventDefault();
        let fName = this.state.user.fName.data;
        let lName = this.state.user.lName.data;
        let number = this.state.user.number.data;
        if(this.state.user.number.data){
            number = this.state.user.number.data;
            if(number.startsWith('04')){
                number = number.replace('04', '614');
            }else if(number.startsWith('61 4')){
                number = number.replace('61 4', '614');
            }else if(number.startsWith('+614')){
                number = number.replace('+614', '614');
            }else if(number.startsWith('+61 4')){
                number = number.replace('+61 4', '614');
            }
            number = number.replace(/\s/g, '');
            fName = fName.trim();
            lName = lName.trim();
        }
        //creating cognito paramaters to send to Lambda script, which in return creates a cognito account
        let lowerCaseEmail = this.state.user.email.data;

        var userparamslambda = {
            Username: lowerCaseEmail.toLowerCase(),
            UserAttributes: [
                { Name: "given_name", Value: fName },
                { Name: "family_name", Value: lName},
                { Name: "name", Value: fName + " " + lName},
                { Name: "email", Value: lowerCaseEmail.toLowerCase()},
                { Name: "phone_number", Value: "+"+number},

            ],
            user:this.state.user,
            wages: this.state.wageInfo,
            userRole:this.state.userRole,
            type: "create"
        };

        this.setState({success:null,failure:null, isLoading: true });
        try {
            let storesArray = [];
            storesArray.push(await this.getSingleStore(this.state.selectedStore.value))
            await this.props.createUser(userparamslambda,this.state.userRole.role,storesArray);
        } catch (e) {
            this.props.errorNotification('There might already be a user with this email address. Contact DEV Support to have their account reset.');
            console.log(e);
        }
        this.setState({ isLoading: false });
    };

    validateDate (trialDate) {
        var date = moment(trialDate, 'DD/MM/YYYY',true);
        var today = moment(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1));
        var startDate = moment(new Date(today));
        var endDate   = moment(new Date(2026, 1, 15));
        if(date.isValid()){
            if(date.isBetween(startDate,endDate))
            {
                return true
            }else if( !date.isBetween(startDate,endDate)) {
                return false
            }
        } else if(trialDate.length>3 && trialDate.length<10){
            return false
        } else return false
    }

    renderForm() {
        var selectedStore = this.state.selectedStore;
        var selectedType = this.state.selectedType;
        var selectedRole = this.state.selectedRole;
        return (

            <form onSubmit={this.handleSubmit} className="form-container add-form">
                <h1 className="heading-text">Create a new applicant</h1>
                <div>
                        <Row>
                            <Col sm={12} md={8} lg={6}>
                                    <Select
                                        className = "store-select"
                                        label = "Store details"
                                        field="Select a Store"
                                        id="store"
                                        onValidate={this.getValidationState('trial_date')}
                                        selectedOption={selectedStore}
                                        onChange={(e) => this.inputHandle('store',"home_store",e)}
                                        options={this.state.stores_dropdown}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={4} lg={3}>
                                    <Select
                                        field ="Role"
                                        label={"Role"}
                                        selectedOption={selectedRole}
                                        onChange={(e) => this.handleUserRoleChange('role',"role",e)}
                                        options={this.state.roleTable}
                                        noOptionsMessage={'loading...'}
                                    />
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                    <Select
                                            id="type"
                                            label={"Type"}
                                            field ="Type"
                                            selectedOption={selectedType}
                                            onChange={(e) => this.handleUserRoleChange('type',"trial_type",e)}
                                            options={this.state.typeTable}
                                        />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={4} lg={3}>
                                    <DateInput
                                        required
                                        label = {this.state.selectedType ? (this.state.selectedType.value === "INDUCTION" ? "Induction Date" : "Trial Date"):"Date"}
                                        id="date"
                                        className={!this.validateDate(this.state.userRole.trial_date)  ? "is-invalid": null}
                                        value={this.state.userRole.trial_date}
                                        onChange={(e) => this.handleUserRoleChange(null,"trial_date", e.target.value)}
                                        name={"trial-date"}/>
                            </Col>
                            <Col sm={6} md={4} lg={3}>
                                    <TimeInput
                                        onValidate={this.getValidationState('trial_time')}
                                        label = {this.state.selectedType ? (this.state.selectedType.value === "INDUCTION" ? "Induction Time" : "Trial Time"):"Time"}
                                        required
                                        onChange={(e,m) => this.handleUserRoleChange( null ,"trial_time",m)}
                                    />
                            </Col>
                        </Row>
                    <Row >
                        <Col sm={2}>
                                <Checkbox
                                    onChange={e => this.handleUserRoleChange(null,"christmas_casual", e.target.checked)}
                                    text={"This is a Christmas Casual"}
                                />
                        </Col>
                        <Col sm={1}>
                        </Col>
                    </Row>
                </div>
                    <h3 className = "heading-text">Contact Details</h3>
                        <Row>
                            <Col sm={12} md={8} lg={6}>
                                        <Input
                                            id="email"
                                            placeholder = "Email Address"
                                            value={this.getFormValue("email.data")}
                                            onChange={e => this.handleChange("email.data", e.target.value)}
                                            onValidate={this.getValidationState("email")}
                                            required

                                        />
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={8} lg={6}>
                                    <Input
                                        id="name"
                                        placeholder = "First Name"
                                        value={this.getFormValue("fName.data")}
                                        onChange={e => this.handleChange("fName.data", e.target.value)}
                                        type="text"
                                        required
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={8} lg={6}>
                                    <Input
                                        placeholder = "Last Name"
                                        style={{ textTransform: 'capitalize'}}
                                        value={this.getFormValue("lName.data")}
                                        onChange={e => this.handleChange("lName.data", e.target.value)}
                                        type="text"
                                        required
                                    />
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={12} md={8} lg={6}>
                                    <Input
                                        onValidate={this.getValidationState("number")}
                                        pattern="(?:\+?61)?(?:\(0\)[23478]|\(?0?[23478]\)?)\d{8}"
                                        placeholder="Phone Number"
                                        value={this.state.user.number.data}
                                        onChange={e => this.handlePhoneNumberChange("number","number.data",e.target.value)}
                                        required
                                    />
                            </Col>
                        </Row>
                <div className={"small-message"}>Remember you must add a published shift in Workforce for all planned trial shifts so Payroll process their pay.</div>
                {this.state.success ? <h4 className = "success-sub-heading-text">Applicant has been sent an email & SMS.</h4> :null}
                {this.state.failure ? <h4 className="fail-sub-heading-text">Applicant Already Exists, Please Retry</h4> : null}
                {this.state.failure ? <p className="fail-sub-heading-text">You may have clicked the submit button twice. Ask the applicant to check for an SMS and email from us. If they did not receive any communications, send hr@universalstore.com.au
                    the applicant's details and we will check their account.</p> : null}
                <Row>
                    <Col sm={3}>
                            <LoaderButton
                                className="btn-block"
                                variant={"dark"}
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                text="SEND EMAIL & SMS"
                            />
                    </Col>
                </Row>
            </form>

        );
    }
    render(){
        return (
            <div className="page-div">
                <div>
                    { this.renderForm()}
                </div>
            </div>
        );
    }
}

const AddPage = connect(mapStateToProps,mapDispatchToProps)(Add);

export default AddPage;
